import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { RootState } from '../reducers/rootReducer';
import { getOrders, getRecallOrders, setOrders, setOrderStatus } from '../actions/orders.actions';
import { setLoading } from '../actions/loading.actions';
import { mapGetOrdersResponseToSetOrdersPayload } from './getOrders.mappers';

import { BFF_ORDERS, BFF_ROOT, BFF_STORES, BFF_V1, ORDER_TYPE_COMMERCIAL, SAGA_GET_ORDERS, VERIFIED_ORDERS , COMMERCIAL_CUSTOMER_SERVICE_DOWN, ORDER_SERVICE_ERROR } from '../../constants';



import { GetOrdersPayload } from '../actions/orders.types';
import { AppLogType, ConfigAuthValues, Order , APIError} from '../../types';

import { callAPI, determineDeviceType, stripLeadingZeroes } from '../../utils';

import { handleApiErrors } from './utils';

import { removeRecallOrders } from '../../recallStorageUtils';

import { OrdersState } from '../reducers/types';
import dingSound from '../../assets/audio/ding.mp3';
import eoSound from '../../assets/audio/acsyhaow.mp3';
import { sendLog } from '../actions/log.actions';

const callGetOrders = (orderCallData: GetOrdersPayload, configValues: ConfigAuthValues) => {
  return callAPI({
    method: 'GET',
    url: `${configValues.API_ROOT}${BFF_ROOT}${BFF_V1}${BFF_STORES}/${orderCallData.fulfillmentStoreID}${BFF_ORDERS}`,
  });
};

export function* runGetOrdersSaga(action: { type: string; payload: GetOrdersPayload }) {
  yield put(setLoading({ sagaName: SAGA_GET_ORDERS, isLoading: true }));
  const configValues = yield select((state: RootState) => state.config);

  const ordersState: OrdersState = yield select((state: RootState) => state.orders);
  const ding = new Audio(dingSound);
  const eo = new Audio(eoSound);

  yield put(
    sendLog({
      logEvent: !ordersState.orders.length ? AppLogType.ORDERS_OPENED : AppLogType.ORDERS_REFRESH,
      logDetails: {
        ordersRetrieved: ordersState?.orders?.length || 'n/a',
      },
    })
  );

  try {
    let result;
    let cachedOrders = localStorage.getItem(VERIFIED_ORDERS)
    if (cachedOrders){
      cachedOrders = JSON.parse(cachedOrders);
      result = {data:cachedOrders}
      localStorage.removeItem(VERIFIED_ORDERS)
    }
    if (!cachedOrders){
      result = yield call(
        callGetOrders,
        {
          fulfillmentStoreID: action.payload.fulfillmentStoreID,
        },
        configValues.authValues
      );
    }

    yield put(setOrderStatus({ lastAttemptSuccess: true, lastAttemptTimeStamp: Date.now() }));

    const pickTourOrderIds = mapGetOrdersResponseToSetOrdersPayload(result.data).pickTours.map(tour =>
      tour.metadata.ordersSummaryData.map(order => order.orderID)
    );
    const flattenedIds = pickTourOrderIds.reduce((acc, curVal) => {
      return [...acc, ...curVal];
    }, []);
    const localStorageRecallData = localStorage.getItem('storeRecallData');
    if (localStorageRecallData) {
      const parsedData = JSON.parse(localStorageRecallData);
      const currentStoreData = parsedData[stripLeadingZeroes(action.payload.fulfillmentStoreID)];
      if (currentStoreData) {
        const currentStoreRecallOrders = currentStoreData.recallOrders;
        const recallIdsToRemove: string[] = currentStoreRecallOrders
          .filter((recallOrder: Order) => flattenedIds.includes(recallOrder.id))
          .map((order: Order) => order.id);
        if (recallIdsToRemove.length > 0) {
          removeRecallOrders(recallIdsToRemove, action.payload.fulfillmentStoreID);
        }
      }
    }

    const deviceType = determineDeviceType();
    const mappedOrders = mapGetOrdersResponseToSetOrdersPayload(result.data);

    if (action.payload.refreshOrders) {
      if (deviceType !== 'Desktop') {
        const prevCommercialOrderIDs = ordersState.orders
          .filter(order => order.type === ORDER_TYPE_COMMERCIAL)
          .map(order => order.id);
        if (
          mappedOrders.orders.some(
            order => order.type === ORDER_TYPE_COMMERCIAL && !prevCommercialOrderIDs.includes(order.id)
          )
        ) {
          ding.play();
        }
      } else if (mappedOrders.orders.some(order => order.type === ORDER_TYPE_COMMERCIAL)) {
        eo.play();
      }
    }

    // TODO: Validate API response
    yield put(setOrders(mappedOrders));
    if (configValues.recallPickingEnabled) {
      yield put(getRecallOrders({ fulfillmentStoreID: action.payload.fulfillmentStoreID }));
    }
   

    if(result.data.errors.length > 0) {

     const filteredErrors = result.data.errors.filter((err: APIError) => !(err.code=== COMMERCIAL_CUSTOMER_SERVICE_DOWN && err.detail.toLowerCase().includes('unable to check UPC validation flag'.toLowerCase())))
     yield handleApiErrors(filteredErrors);

    } else {
      yield handleApiErrors(result.data.errors);
    }
  } catch (error) {
    yield put(setOrderStatus({ lastAttemptSuccess: false, lastAttemptTimeStamp: Date.now() }));
    yield put(setOrders({ orders: [], pickTours: [] }));
    
    const orderError: APIError[] = [{id: '',
    code: ORDER_SERVICE_ERROR,
    detail: JSON.stringify(error)}]

    yield handleApiErrors(orderError);
    
  }

  yield put(setLoading({ sagaName: SAGA_GET_ORDERS, isLoading: false }));
}

export default function* watchGetOrders() {
  yield takeEvery(getType(getOrders), runGetOrdersSaga);
}
