import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ORDER_TYPE_COMMERCIAL, ORDER_TYPE_OVERSTOCK } from '../../../constants';
import { OrderType } from '../../../types';
import ItemQuantityBox from '../../layout/ItemQuantityBox';

import styles from './DetailsViewCardHeader.css';

type Props = {
  keepQuantityOnHand: number | null;
  description: string;
  pickedQuantity: number;
  totalQuantity: number;
  hasJustScanned: boolean;
  orderType: OrderType;
  quantityOnHand: number | null;
};

const DetailsViewCardHeader = ({
  keepQuantityOnHand,
  description,
  pickedQuantity,
  totalQuantity,
  hasJustScanned,
  orderType,
  quantityOnHand,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const translationRoot = 'ACTIVE_PICK_TOUR.DETAILS_VIEW';

  let showKeepQty = true;
  let showQOH = true;
  if (orderType === ORDER_TYPE_COMMERCIAL) {
    showKeepQty = false;
  } else if (orderType === ORDER_TYPE_OVERSTOCK) {
    showKeepQty = false;
    showQOH = false;
  }

  return (
    <header className={styles.header}>
      <h6 className={styles.itemName} data-cy="pick-tour-item-description">
        {description}
      </h6>
      <div className={styles.itemQtyRow}>
        <div className={styles.keepQtyRow}>
          <span>
            {showQOH && (
              <>
                <span>{t(`${translationRoot}.QOH_LABEL`)}</span>
                <span className={styles.amount}>{quantityOnHand}</span>
              </>
            )}
            {showKeepQty && (
              <>
                <span className={styles.keepLabel}>{t(`${translationRoot}.KEEP_LABEL`)}</span>
                <span className={styles.amount}>{keepQuantityOnHand || 0}</span>
              </>
            )}
          </span>
        </div>
        <ItemQuantityBox
          pickedQuantity={pickedQuantity}
          totalQuantity={totalQuantity}
          hasJustScanned={hasJustScanned}
        />
      </div>
    </header>
  );
};

export default DetailsViewCardHeader;
