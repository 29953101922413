import React, { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import CheckmarkSquareIcon from '../../../../assets/icons/CheckmarkSquareIcon';
import ScanNotRequired from '../../../../assets/icons/ScanNotRequiredIcon';

import { ORDER_TYPE_COMMERCIAL, ORDER_TYPE_OVERSTOCK, ORDER_TYPE_RECALL } from '../../../../constants';

import { Order } from '../../../../types';

import {
  displayOrderType,
  determineIconByOrderType,
  displayDestination,
  displayDate,
  mergeClassNames,
} from '../../../../utils';
import CheckBoxSpinner from '../../../layout/Spinner/CheckBoxSpinner';

import styles from './OrderItem.css';

type Props = {
  order: Order;
  isSelected: boolean;
  onSelect: () => void;
  currentLanguage: string;
  verifying: boolean;
};

const OrderItem = ({ isSelected, order, onSelect, currentLanguage, verifying }: Props): ReactElement => {
  const { t } = useTranslation();
  const translationRoot = 'ORDER_LIST.LIST_ITEM';

  const displayOrderDestination = () => {
    if (order.type === ORDER_TYPE_RECALL) {
      return `${t(`${translationRoot}.RECALL`)} | ${order.id} | ${order.recallDescription}`;
    }
    return displayDestination({ orderType: order.type, destination: order.destination });
  };

  return (
    <li className={styles.li} onClick={() => onSelect()} data-cy={`order-list-item-${order.id}`}>
      {verifying ? (
        <div className={styles.checkboxArea}>
          <CheckBoxSpinner />
        </div>
      ) : (
        <div className={styles.checkboxArea}>
          {isSelected ? <CheckmarkSquareIcon /> : <div className={styles.circle} data-cy="circle-icon" />}
        </div>
      )}

      <div className={styles.mainArea}>
        <div
          className={mergeClassNames(styles.destination, order.type === ORDER_TYPE_COMMERCIAL && styles.capitalize)}
          data-cy="pickTour-destination"
        >
          {displayOrderDestination()}
        </div>
        <div className={styles.content}>
          <div className={styles.orderType}>
            <div className={styles.icon}>{determineIconByOrderType({ orderType: order.type })}</div>
            <p data-cy="pickTour-type">{displayOrderType(order.type, order.deliveryMethod, order.destination)}</p>
          </div>
          <div className={mergeClassNames(styles.itemsPick, currentLanguage === 'es-MX' && styles.itemsPickSpanish)}>
            <span>{t(`${translationRoot}.ITEMS_TO_PICK`)}</span>
            <span className={styles.quantity}>
              {order.type !== ORDER_TYPE_OVERSTOCK ? order.quantity : t(`${translationRoot}.TBD`)}
            </span>
          </div>
          <div>
            <span className={styles.textLight}>{`${
              order.type === ORDER_TYPE_RECALL ? t(`${translationRoot}.DUE`) : ''
            } ${displayDate({ date: order.date }).split('-')[0]}`}</span>
            <span className={styles.orderTime}>{displayDate({ date: order.date }).split('-')[1]}</span>
          </div>
          <div className={styles.highPriority}>
            {!order.isUpcScanRequired && (
              <>
                <span className={styles.highPriorityIcon}>
                  <ScanNotRequired />
                </span>
                <span className={styles.textLight}>{t(`${translationRoot}.SCAN_NOT_REQUIRED`)}</span>
              </>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default OrderItem;
